import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { IconButton } from '@mui/material';

import { ArrowLeft } from './ArrowLeft';
import { CloseRight } from './CloseRight';

interface IPropsIcons {
  action: () => void;
  type: string;
}

export const Icons: FC<IPropsIcons> = ({ action, type }) => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const isSmallMobile = useMediaQuery({ maxWidth: 374 });

  return (
    <IconButton
      sx={{
        zIndex: 1,
        backgroundColor: 'white',
        width: isSmallMobile ? '28px' : isMobile ? '31px' : '34px',
        height: isSmallMobile ? '28px' : isMobile ? '31px' : '34px',
        padding: '3px',
        borderRadius: '5px',
        boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.16)',
        '&:hover': {
          backgroundColor: isMobile ? 'white' : 'black'
        },
        '&:hover svg': {
          color: isMobile ? 'black !important' : 'white !important'
        },
        '&:active': {
          backgroundColor: isMobile ? 'black' : 'white'
        },
        '&:active svg': {
          color: isMobile ? 'white !important' : 'black !important'
        }
      }}
      onClick={action}
    >
      {type === 'back' && <ArrowLeft />}
      {type === 'close' && <CloseRight />}
    </IconButton>
  );
};
