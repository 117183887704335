import React, { FC, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { recommend } from '../../../api/endpoints';

import './loading.scss';
import { useMediaQuery } from 'react-responsive';

interface IPropsResultLoading {
  nextStep: (value: boolean) => void;
  sendAnswers: () => any;
  setRecommendedSize: (value: any) => void;
}

const ResultLoading: FC<IPropsResultLoading> = ({
  nextStep,
  sendAnswers,
  setRecommendedSize
}) => {
  const [value, setValue] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const { t } = useTranslation('components/results/loading');

  useEffect(() => {
    if (value < 50) {
      const result = sendAnswers()
        .then((result: any) => result)
        .catch((e: string) => console.log('error', e));

      if (result) {
        setTimeout(() => {
          setValue((prev) => prev + 50);
        }, 400);
      }
    }

    if (value === 50) {
      const urlParams = new URLSearchParams(window.location.search);
      const pid = urlParams.get('product_id');
      const reco = recommend(pid)
        .then((result: any) => {
          setRecommendedSize(result);
          setValue((prev) => prev + 50);
          setTimeout(() => {
            nextStep(!!result);
          }, 500);
        })
        .catch((e: any) => {
          console.log('error recommend:', e);
        });
    }
  }, [value]);

  return (
    <div className="result_loading">
      <div className="result_loading__progress">
        <div className="result_loading__progress__loader">
          <CircularProgress
            size={isMobile ? 200 : 250}
            className="result_loading__progress__loader__circle"
            variant="determinate"
            value={100}
          />
          <CircularProgress
            size={isMobile ? 200 : 250}
            className="result_loading__progress__loader__circle_loader"
            variant="determinate"
            value={value}
          />
        </div>
        <div className="result_loading__progress__label">{value}%</div>
      </div>
      <div className="result_loading__text">
        <p className="result_loading__text__description">{t('description')}</p>
        <p className="result_loading__text__tips">{t('tips')}</p>
      </div>
    </div>
  );
};

export default ResultLoading;
