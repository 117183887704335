import React, { FC, useState, useRef, useEffect } from 'react';
import { TextField } from '@mui/material';

import './Ruler.scss';

interface IPropsRuler {
  value: number[];
  onValueChange: (value: number) => void;
  units: string[];
  unit: string;
  onUnitChange: (unit: string) => void;
  error: {
    error: boolean;
    message: string;
  };
}

const Ruler: FC<IPropsRuler> = ({
  value,
  onValueChange,
  units,
  unit,
  onUnitChange,
  error
}) => {
  const rulerId = useRef((Math.random() + 1).toString(36).substring(7));
  const unitsDetails = {
    cm: {
      step: 10,
      min: 140,
      max: 230,
      showUnits: true,
      graduation: (v: number) => {
        return v.toString();
      },
      convert: (v: string) => {
        const numbers = [
          parseInt(v.substring(0, 1)) || 0,
          parseInt(v.substring(1)) || 0
        ];
        const newValue = Math.round(numbers[0] * 30.48 + numbers[1] * 2.54);

        return newValue > unitsDetails.cm.max ? unitsDetails.cm.max : newValue;
      },
      input: {
        display: (v: string) => {
          return v;
        },
        format: (v: string) => {
          const newValue = v.replace(/\D/g, '');

          return newValue;
        },
        convert: (v: number) => {
          return v.toString();
        },
        normalize: (v: string) => {
          return v;
        }
      }
    },
    feet: {
      step: 6,
      min: 7,
      max: 43,
      showUnits: true,
      graduation: (v: number) => {
        return `${4 + Math.floor(v / 12.0)}'${v - 12 * Math.floor(v / 12.0)}"`;
      },
      convert: (v: string) => {
        const number = parseInt(v) || 0;

        return parseInt(
          `${Math.floor(number / 2.54 / 12)}${Math.round(
            number / 2.54 - 12 * Math.floor(number / 2.54 / 12)
          )}`
        );
      },
      input: {
        display: (v: string) => {
          if (v === undefined || v === '') {
            return '';
          } else {
            return `${v.toString().substring(0, 1)}${
              v.toString().slice(1) === '' ? "'‎" : "'"
            }${v.toString().slice(1)}${
              v.toString().slice(1) === '' ? '' : '"‎'
            }`;
          }
        },
        format: (v: string) => {
          const newValue =
            v.match('["\']') !== null &&
            v.search("'‎[0-9]") === -1 &&
            v.search(v.slice(1) === '' ? "'‎" : '"‎') === -1
              ? v.replace(/\D/g, '').slice(0, -1)
              : parseInt(v.replace(/\D/g, '').slice(1)) > 11
                ? v.replace(/\D/g, '').slice(0, -1)
                : v.replace(/\D/g, '');

          return newValue.length > 3 ? newValue.substring(0, 3) : newValue;
        },
        convert: (v: number) => {
          const numbers = [
            4 + Math.floor(v / 12.0),
            v - 12 * Math.floor(v / 12.0)
          ];

          return `${numbers[0]}${numbers[1] !== 0 ? numbers[1] : ''}`;
        },
        normalize: (v: string) => {
          const numbers = [
            parseInt(v.substring(0, 1)) || null,
            parseInt(v.substring(1)) || null
          ];

          if (numbers[0] !== null && numbers[1] === null)
            return ((numbers[0] - 4) * 12).toString();
          else if (numbers[0] === null && numbers[1] !== null)
            return (numbers[1] % 12).toString();
          else if (numbers[0] !== null && numbers[1] !== null)
            return ((numbers[0] - 4) * 12 + (numbers[1] % 12)).toString();
        }
      }
    },
    kg: {
      step: 10,
      min: 40,
      max: 140,
      showUnits: true,
      graduation: (v: number) => {
        return v.toString();
      },
      convert: (v: string) => {
        return Math.round(parseInt(v) * 0.45359237);
      },
      input: {
        display: (v: string) => {
          return v;
        },
        format: (v: string) => {
          const newValue = v.replace(/\D/g, '');

          return newValue;
        },
        convert: (v: number) => {
          return v.toString();
        },
        normalize: (v: string) => {
          return v;
        }
      }
    },
    lbs: {
      step: 10,
      min: 88,
      max: 309,
      showUnits: true,
      graduation: (v: number) => {
        return v.toString();
      },
      convert: (v: string) => {
        return Math.round(parseInt(v) / 0.45359237);
      },
      input: {
        display: (v: string) => {
          return v;
        },
        format: (v: string) => {
          const newValue = v.replace(/\D/g, '');

          return newValue;
        },
        convert: (v: number) => {
          return v.toString();
        },
        normalize: (v: string) => {
          return v;
        }
      }
    },
    years: {
      step: 10,
      min: 16,
      max: 80,
      showUnits: false,
      graduation: (v: number) => {
        return v.toString();
      },
      convert: (v: string) => {
        return parseInt(v);
      },
      input: {
        display: (v: string) => {
          return v;
        },
        format: (v: string) => {
          const newValue = v.replace(/\D/g, '');

          return newValue;
        },
        convert: (v: number) => {
          return v.toString();
        },
        normalize: (v: string) => {
          return v;
        }
      }
    }
  };
  const unitDetails =
    Object.entries(unitsDetails).find(([key]) => key === unit)?.[1] ||
    unitsDetails['cm'];
  const [ruler, setRuler] = useState({
    input:
      unit === 'feet'
        ? `${value[0]}${value[1]}`
        : value[0].toString() || unitDetails.min.toString(),
    value:
      unit === 'feet'
        ? ((value[0] - 4) * 12 + (value[1] % 12)).toString()
        : value[0].toString() || unitDetails.min.toString()
  });
  const steps = [];
  let pos = { top: 0, left: 0, x: 0, y: 0 };

  useEffect(() => {
    onValueChange(parseInt(ruler.value) || (unit === 'feet' ? -48 : 0));
  }, [ruler]);

  const detectCurrent = () => {
    const container = document.getElementById(
      `ruler-content-${rulerId.current}`
    );

    [].slice.call(container?.children).forEach((ele: HTMLElement) => {
      if (container) {
        const newSize =
          unitDetails.min +
          Math.floor(
            Math.abs(
              ele.getBoundingClientRect().left -
                container.getBoundingClientRect().left
            ) / 7
          );

        setRuler({
          input: unitDetails.input.convert(newSize),
          value: newSize.toString()
        });
      }
    });
  };

  const mouseMoveHandler = function (e: any) {
    const ele = document.getElementById(
      `ruler-content-${rulerId.current}`
    ) as HTMLElement;
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    ele.scrollTop = pos.top - dy;
    ele.scrollLeft = pos.left - dx;
  };

  const mouseUpHandler = function () {
    const ele = document.getElementById(
      `ruler-content-${rulerId.current}`
    ) as HTMLElement;

    ele.style.cursor = 'grab';
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  };

  const mouseDownHandler = function (e: any) {
    const ele = document.getElementById(
      `ruler-content-${rulerId.current}`
    ) as HTMLElement;

    ele.style.cursor = 'grabbing';
    pos = {
      left: ele.scrollLeft,
      top: ele.scrollTop,
      x: e.clientX,
      y: e.clientY
    };
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  useEffect(() => {
    const element = document.getElementById(
      `ruler-content-${rulerId.current}-graduation-${ruler.value}`
    );

    if (element) {
      element.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      });
    }

    document
      .getElementById(`ruler-content-${rulerId.current}`)
      ?.addEventListener('scroll', () => detectCurrent());
    document
      .getElementById(`ruler-content-${rulerId.current}`)
      ?.addEventListener('mousedown', mouseDownHandler);
  }, [rulerId, unit]);

  for (let i = unitDetails.min; i <= unitDetails.max; i += 1)
    steps.push(
      <div
        key={i}
        id={`ruler-content-${rulerId.current}-graduation-${i}`}
        className="ruler__container__content__box__graduation"
      >
        <div
          className={`${
            i % unitDetails.step === 0
              ? 'ruler__container__content__box__graduation__cm'
              : 'ruler__container__content__box__graduation__mm'
          }`}
        />
        <span className="ruler__container__content__box__graduation__cm__number">
          {i % unitDetails.step === 0 ? unitDetails.graduation(i) : null}
        </span>
      </div>
    );

  return (
    <div className="ruler">
      <div className="ruler__input">
        <TextField
          InputProps={{
            inputProps: {
              style: {
                textAlign: 'center',
                color: error.error ? 'red' : 'black'
              }
            }
          }}
          className="ruler__input__textfield"
          variant="outlined"
          value={unitDetails.input.display(ruler.input)}
          onChange={(v) => {
            const newValue = v.target.value;
            const element = document.getElementById(
              `ruler-content-${
                rulerId.current
              }-graduation-${unitDetails.input.normalize(
                unitDetails.input.format(newValue)
              )}`
            );

            if (element) {
              element.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
              });
            }

            setRuler({
              input: unitDetails.input.format(newValue),
              value:
                unitDetails.input.normalize(
                  unitDetails.input.format(newValue)
                ) || ''
            });
          }}
        />
        <div className="ruler__input__units">
          {unitDetails.showUnits === true
            ? units.map((unitValue) => {
                const isSelected = unitValue === unit;

                return (
                  <button
                    className={`ruler__input__units__buttons ${
                      isSelected
                        ? 'ruler__input__units__buttons--selected'
                        : undefined
                    }`}
                    key={unitValue}
                    onClick={() => {
                      if (unitValue === unit) {
                        return;
                      }
                      const newValue =
                        Object.entries(unitsDetails)
                          .find(([key]) => key === unitValue)?.[1]
                          .convert(ruler.input) || 0;

                      setRuler({
                        input: newValue?.toString() || '',
                        value:
                          Object.entries(unitsDetails)
                            .find(([key]) => key === unitValue)?.[1]
                            .input.normalize(newValue.toString()) || ''
                      });

                      onUnitChange(unitValue);
                    }}
                  >
                    {unitValue}
                  </button>
                );
              })
            : null}
        </div>
      </div>
      <div className="ruler__container">
        <div
          style={{ backgroundColor: error.error ? 'red' : 'black' }}
          className="ruler__container__selector"
        />
        <div
          id={`ruler-content-${rulerId.current}`}
          className="ruler__container__content"
        >
          <div id="ruler-box" className="ruler__container__content__box">
            {steps}
          </div>
        </div>
      </div>
      {error.error ? <p className="ruler__error">{error.message}</p> : null}
    </div>
  );
};

export default Ruler;
