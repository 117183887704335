import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import './desktop.scss';
import Ruler from '../../../molecules/Ruler/Ruler';

interface IPropsWeightDesktop {
  units: { height: string; weight: string };
  setUnits: (value: { height: string; weight: string }) => void;
  weight: number[] | undefined;
  setWeight: (value: number | undefined) => void;
  error: {
    error: boolean;
    message: string;
  };
}

const WeightDesktop: FC<IPropsWeightDesktop> = ({
  units,
  setUnits,
  weight,
  setWeight,
  error
}) => {
  const { t } = useTranslation('components/measures');

  return (
    <div className="weight_desktop">
      <p className="weight_desktop__description">{t('weight.description')}</p>
      <Ruler
        value={weight || [0]}
        onValueChange={(value) => {
          setWeight(value);
        }}
        units={['kg', 'lbs']}
        unit={units.weight}
        onUnitChange={(unit) => setUnits({ ...units, weight: unit })}
        error={error}
      />
    </div>
  );
};

export default WeightDesktop;
