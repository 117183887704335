import React, { FC } from 'react';

import './Stepper.scss';

interface IPropsStepper {
  stepsNum: number;
  step: number;
  className?: string;
  style?: React.CSSProperties;
}

const Stepper: FC<IPropsStepper> = ({ stepsNum, step, className, style }) => {
  const stepRender = (stepNum: number, step: number) => {
    const steps = [];

    for (let i = 0; i < stepNum; i += 1)
      steps.push(
        <div
          key={i}
          className={`stepper__step ${
            i + 1 === step ? 'stepper__step--active' : undefined
          }`}
        />
      );

    return steps;
  };

  return (
    <div className={`stepper ${className}`}>{stepRender(stepsNum, step)}</div>
  );
};

export default Stepper;
