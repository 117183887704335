import React, { FC, useState } from 'react';
import QRCode from 'react-qr-code';
import Lottie from 'lottie-react';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '../../../../context/AppContext';

import './desktop.scss';

interface IPropsQrcodeDesktop {
  showSkip?: boolean;
  skipScan?: () => void;
  uid?: string;
  mid?: string;
  gender?: string;
  pid?: string;
  url?: string;
  loading?: boolean;
}

const QrcodeDesktop: FC<IPropsQrcodeDesktop> = ({
  showSkip,
  skipScan,
  gender,
  uid,
  mid,
  pid,
  url,
  loading
}) => {
  const appContext = useAppContext() || undefined;
  const { t } = useTranslation('components/qrcode/desktop');
  const [inProgress, setInProgress] = useState(false);

  return (
    <div className="qrcode_desktop">
      <div className="qrcode_desktop__video">
        <video muted playsInline autoPlay loop>
          <source
            src={
              gender === 'male'
                ? require('../../../../assets/qrcode/demo-male.mp4')
                : require('../../../../assets/qrcode/demo-female.mp4')
            }
            type="video/mp4"
          />
        </video>
        {false && (
          <button
            className="qrcode_desktop__video__help"
            //onClick={() => setHelpDialog(true)}
          >
            <img
              className="qrcode_desktop__video__help__icon"
              src={require('../../../../assets/help.png')}
            />
            {t('help.title')}
          </button>
        )}
        <div className="qrcode_desktop__video__copyright">
          <p>Measured by</p>
          <img
            style={{
              width: '32px',
              marginLeft: '3px',
              marginBottom: '4px',
              height: 'auto'
            }}
            src={require('../../../../assets/logo/kleep.png')}
          />
        </div>
      </div>
      <div className="qrcode_desktop__body">
        <div className="qrcode_desktop__body__text">
          <p className="qrcode_desktop__body__text__title">
            {inProgress === false
              ? appContext?.style.caps === true
                ? t('title.start').toUpperCase()
                : t('title.start')
              : appContext?.style.caps === true
                ? t('title.restart').toUpperCase()
                : t('title.restart')}
          </p>
          <p className="qrcode_desktop__body__text__description">
            {inProgress === false
              ? t('description.start')
              : t('description.restart')}
          </p>
        </div>
        <div className="qrcode_desktop__body__banner">
          {!loading ? (
            <QRCode
              className="qrcode_desktop__body__banner__qrcode"
              size={120}
              value={`${url}`}
              viewBox={'0 0 256 256'}
            />
          ) : (
            <Lottie
              className="qrcode_desktop__body__banner__qrcode_animation"
              animationData={require('../../../../assets/animations/qrcode_loader.json')}
            />
          )}
          <div className="qrcode_desktop__body__banner__text">
            <p className="qrcode_desktop__body__banner__text__title">
              {inProgress === false
                ? t('banner.title.start')
                : t('banner.title.restart')}
            </p>
            <p className="qrcode_desktop__body__banner__text__description">
              {t('banner.description')}
            </p>
          </div>
        </div>
        {showSkip === true && (
          <button className="qrcode_desktop__body__skip" onClick={skipScan}>
            {t('banner.skip')}
          </button>
        )}
      </div>
    </div>
  );
};

export default QrcodeDesktop;
