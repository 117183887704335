import React, { FC } from 'react';

import './Filter.scss';

interface IPropsFilter {
  type?: string;
}

export const Filter: FC<IPropsFilter> = ({ type }) => (
  <div
    className={type}
    style={{
      position: 'absolute',
      width: '100%',
      height: '100%'
    }}
  />
);
