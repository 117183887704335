import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Select, MenuItem } from '@mui/material';

interface IPropsFlags {
  language: string;
  action: (input: string) => void;
}

export const Flags: FC<IPropsFlags> = ({ language, action }) => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const isSmallMobile = useMediaQuery({ maxWidth: 374 });

  return (
    <Select
      value={language}
      onChange={(event) => action(event.target.value)}
      sx={{
        backgroundColor: 'white',
        width: isSmallMobile ? '28px' : isMobile ? '31px' : '34px',
        height: isSmallMobile ? '28px' : isMobile ? '31px' : '34px',
        padding: 0,
        margin: 0,
        borderRadius: '5px !important',
        boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.16)',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            border: 0
          }
      }}
      IconComponent={() => null}
      inputProps={{
        sx: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0,
          paddingRight: '0 !important'
        }
      }}
      MenuProps={{
        MenuListProps: {
          sx: {
            padding: 0,
            '& .Mui-selected': {
              display: 'none'
            }
          }
        },
        PaperProps: {
          sx: {
            padding: '0 !important',
            boxShadow: '0px 4px 5px -3px rgba(0, 0, 0, 0.16) !important',
            bgcolor: 'white',
            borderRadius: '0px 0px 5px 5px !important',
            transform: 'translateY(-4px) !important',

            '& .MuiMenuItem-root': {
              padding: 2
            }
          }
        }
      }}
    >
      <MenuItem
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 0,
          padding: '8px 0 !important'
        }}
        value="fr"
      >
        <span className="kleep-modal__flag-icons FR" />
      </MenuItem>
      <MenuItem
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 0,
          padding: '7px 0 !important'
        }}
        value="en"
      >
        <span className="kleep-modal__flag-icons GB" />
      </MenuItem>
      <MenuItem
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 0,
          padding: '7px 0 !important'
        }}
        value="de"
      >
        <span className="kleep-modal__flag-icons DE" />
      </MenuItem>
    </Select>
  );
};
