import React, {
  useContext,
  createContext,
  useEffect,
  useState,
  FC
} from 'react';
import { getConfig } from '../api/endpoints';

interface IAppContext {
  referer: string | null;
  pid: string | null;
  flow: {
    genders?: {
      default?: any;
      male?: any;
      female?: any;
    };
    emphasizedScan?: boolean;
    similarProducts?: boolean;
    defaultLang?: string;
  };
  style: {
    intro?: string;
    caps?: boolean;
    cta?: {
      color?: string;
      borderRadius?: string;
      case?: string;
    };
    font?: string;
  };
  uniqueGender: string | null;
  setPid: (value: string) => void;
  setReferer: (value: string) => void;
  extractRefererFromUrl: () => string | null;
  extractPidFromUrl: () => string | null;
  extractLangFromUrl: () => string | null;
}

const AppContext = createContext<IAppContext | null>(null);

interface IPropsAppProvider {
  children: JSX.Element;
}

const AppProvider: FC<IPropsAppProvider> = ({ children }) => {
  const [referer, setReferer] = useState<string | null>(null);
  const [uniqueGender, setUniqueGender] = useState<string | null>(null);
  const [pid, setPid] = useState<string | null>(null);
  const [flow, setFlow] = useState({
    genders: undefined,
    enphasizedScan: false,
    similarProducts: false
  });
  const [style, setStyle] = useState({
    caps: true,
    intro: 'black',
    cta: {
      color: 'black',
      borderRadius: '5px'
    },
    font: 'Arial'
  });

  function extractLangFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('lang');
  }

  function extractRefererFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('domain');
  }

  function extractPidFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('product_id');
  }

  async function awaitGetConfig() {
    let initialConfig = null;
    let initialReferer = referer;
    if (referer) {
      initialConfig = await getConfig(referer);
    } else {
      initialReferer = extractRefererFromUrl();
      if (initialReferer) {
        setReferer(initialReferer);
        initialConfig = await getConfig(initialReferer);
      }
    }
    return initialConfig;
  }

  useEffect(() => {
    const initialRefrer = extractRefererFromUrl();
    setReferer(initialRefrer);
  }, []);

  useEffect(() => {
    const initialPid = extractPidFromUrl();
    setPid(initialPid);
  }, []);

  useEffect(() => {
    awaitGetConfig()
      .then((result) => result.modal_config)
      .then((result) => {
        if (result) {
          setFlow((prev) => result?.flow ?? prev);
          setStyle((prev) => result?.style ?? prev);
          const availableGenders = Object.keys(result?.flow?.genders);
          if (
            availableGenders.length === 1 &&
            availableGenders[0] !== 'default'
          ) {
            setUniqueGender(availableGenders[0]);
          }
        }
      })
      .catch((err) => console.log('getConfig error:', err));
  }, [referer]);

  return (
    <AppContext.Provider
      value={{
        referer,
        pid,
        flow,
        uniqueGender,
        style,
        setPid,
        setReferer,
        extractRefererFromUrl,
        extractPidFromUrl,
        extractLangFromUrl
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;

export const useAppContext = () => {
  return useContext(AppContext);
};
