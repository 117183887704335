import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box } from '@mui/material';
import { Icons } from '../molecules';

interface IPropsLeft {
  showGoBack: boolean;
  handleGoBack: () => void;
}

export const Left: FC<IPropsLeft> = ({ showGoBack, handleGoBack }) => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const isSmallMobile = useMediaQuery({ maxWidth: 374 });

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'start',
        textTransform: 'none',
        width: 'fit-content',
        height: isSmallMobile ? '28px' : isMobile ? '31px' : '34px',
        gap: '10px'
      }}
    >
      {showGoBack && <Icons type={'back'} action={handleGoBack} />}
      <img
        style={{ height: '100%', zIndex: 1 }}
        src={require('../../../assets/logo/monogram.png')}
      />
    </Box>
  );
};
