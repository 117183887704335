import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Typography } from '@mui/material';

import { useAppContext } from '../../../../context/AppContext';

interface IPropsTitle {
  title: string;
}

export const Title: FC<IPropsTitle> = ({ title }) => {
  const appContext = useAppContext() || undefined;
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const isSmallMobile = useMediaQuery({ maxWidth: 374 });

  return (
    <Typography
      fontFamily={appContext?.style?.font ?? 'Helvetica'}
      fontSize={isSmallMobile ? '14px' : isMobile ? '17px' : '20px'}
      fontWeight={800}
      textAlign={'center'}
      style={{
        width: '80%',
        lineHeight: isSmallMobile ? '16px' : isMobile ? '19px' : '22px',
        marginBottom: 0,
        cursor: 'default',
        whiteSpace: 'pre-line'
      }}
    >
      {title}
    </Typography>
  );
};
