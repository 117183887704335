import React, { FC, useEffect } from 'react';
import Lottie from 'lottie-react';
import { useMediaQuery } from 'react-responsive';

import './wait.scss';
import { getConfig, recommend } from '../../../api/endpoints';

interface IPropsWait {
  domain?: string | null;
  setConfig: (config: {
    images: {
      male: string | null;
      female: string | null;
      default: string | null;
    } | null;
    font: string | null;
  }) => void;
  intro: () => void;
  result: (r: any) => void;
}

const Wait: FC<IPropsWait> = ({ domain, setConfig, intro, result }) => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });

  useEffect(() => {
    if (
      domain !== null &&
      domain !== undefined &&
      localStorage.getItem('mid')
    ) {
      const urlParams = new URLSearchParams(window.location.search);
      const pid = urlParams.get('product_id');
      getConfig(domain).then((res) => {
        setConfig({
          images: {
            male: res.image_url ?? null,
            female: res.image_url ?? null,
            default: res.image_url ?? null
          },
          font: res.font ?? null
        });
        const reco = recommend(pid)
          .then((r: any) => {
            if (r) {
              result(r);
            } else {
              intro();
            }
          })
          .catch((e: any) => console.log(e));
      });
    } else if (localStorage.getItem('mid')) {
      const urlParams = new URLSearchParams(window.location.search);
      const pid = urlParams.get('product_id');

      setConfig({ images: null, font: null });
      const reco = recommend(pid)
        .then((r: any) => {
          if (r) {
            result(r);
          } else {
            intro();
          }
        })
        .catch((e: any) => console.log(e));
    } else if (domain !== null && domain !== undefined) {
      getConfig(domain).then((res) => {
        setConfig({
          images: {
            male: res.image_url ?? null,
            female: res.image_url ?? null,
            default: res.image_url ?? null
          },
          font: res.font ?? null
        });
        intro();
      });
    } else {
      intro();
    }
  }, []);

  return (
    <div className="wait">
      <Lottie
        animationData={require('../../../assets/animations/loading_black.json')}
        style={{
          width: '200px',
          height: 'auto'
        }}
      />
    </div>
  );
};

export default Wait;
