import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Box } from '@mui/material';

import { useAppContext } from '../../../context/AppContext';

import { Left } from './Left';
import { Flags } from './Flags';

import { Icons, Title } from '../molecules';

import { i18n } from '../../../i18n';

interface IPropsHeader {
  showGoBack: boolean;
  showTitle: boolean;
  handleGoBack: () => void;
  title: string;
  language: string;
  setLanguage: (input: string) => void;
  handleModal: (input1: boolean, input2: boolean) => void;
}

export const Header: FC<IPropsHeader> = ({
  showGoBack,
  handleGoBack,
  showTitle,
  title,
  language,
  setLanguage,
  handleModal
}) => {
  const appContext = useAppContext() || undefined;
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const isSmallMobile = useMediaQuery({ maxWidth: 374 });

  return (
    <Box
      sx={{
        padding: isMobile ? '12px 12px 0 12px' : '20px 20px 0 20px',
        position: 'relative',
        width: '100%',
        height: isMobile ? '46px' : '56px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <Left showGoBack={showGoBack} handleGoBack={handleGoBack} />
        {showTitle && (
          <Title
            title={
              appContext?.style.caps === true
                ? title.toLocaleUpperCase()
                : title
            }
          />
        )}
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'end',
            textTransform: 'none',
            gap: '10px',
            width: 'fit-content',
            minWidth: isSmallMobile ? '28px' : isMobile ? '31px' : '34px',
            height: isSmallMobile ? '28px' : isMobile ? '31px' : '34px'
          }}
        >
          <Flags
            language={language}
            action={(value) => {
              i18n.changeLanguage(value);
              setLanguage(value);
            }}
          />
          <Icons type={'close'} action={() => handleModal(false, false)} />
        </Box>
      </Box>
    </Box>
  );
};
