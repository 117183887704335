import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Ruler from '../../../molecules/Ruler/Ruler';

import './desktop.scss';

interface IPropsAgeDesktop {
  units: { height: string; weight: string };
  setUnits: (value: { height: string; weight: string }) => void;
  age: number[];
  setAge: (value: number | undefined) => void;
  error: {
    error: boolean;
    message: string;
  };
}

const AgeDesktop: FC<IPropsAgeDesktop> = ({
  units,
  setUnits,
  age,
  setAge,
  error
}) => {
  const { t } = useTranslation('components/measures');

  return (
    <div className="age_desktop">
      <p className="age_desktop__description">{t('age.description')}</p>
      <Ruler
        value={age || [0]}
        onValueChange={(value) => {
          setAge(value);
        }}
        units={['years']}
        unit={'years'}
        onUnitChange={(unit) => setUnits({ ...units, height: unit })}
        error={error}
      />
    </div>
  );
};

export default AgeDesktop;
