import React, { useState, useEffect, useRef, FC, Suspense } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogContent } from '@mui/material';

import Lottie from 'lottie-react';

import StepIntroMobile from './intro/mobile/stepIntro';
import StepIntroDesktop from './intro/desktop/stepIntro';
import StepHeight from './measures/height/stepMeasure';
import StepAge from './measures/age/stepMeasure';
import StepWeight from './measures/weight/stepMeasure';
import StepQRCode from './qrcode/stepQrCode';
import StepBelly from './belly/stepBelly';
import StepTorso from './torso/stepTorso';
import StepHips from './hips/stepHips';
import StepBreasts from './breasts/stepBreasts';

import { Filter } from './molecules';

import Wait from './wait/wait';

import '../../i18n';
import { i18n } from '../../i18n';

import { newMeasure, questionPushAnswersAndTrigger } from '../../api/endpoints';
import { useUserContext } from '../../store/userContext';

import {
  getLocalAndParse,
  setLocalStore,
  removeLocalStore
} from '../../store/localStoreUtils';

import Result from './results/result';
import ResultLoading from './results/loading';
import ResultError from './results/error';

import { GENDERS, MODAL_STEPS } from '../../utils';

import { lacosteProducts } from './hardcode_params';

import './KleepModal.scss';
import { useAppContext } from '../../context/AppContext';
import { Header } from './header/Header';
import { Footer } from './footer/Footer';

interface IPropsKleepModal {
  handleModal: (openModal: boolean, size: boolean) => void;
  isModalOpen: boolean;
  setRecommendedSize: (r: any) => void;
  productImg?: string;
  pid?: string;
  already?: boolean;
  setAlready?: (bool: boolean) => void;
}

const KleepModal: FC<IPropsKleepModal> = ({
  handleModal,
  isModalOpen,
  pid
}) => {
  const appContext = useAppContext() || undefined;
  const userStored = getLocalAndParse('user');
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const isSmallMobile = useMediaQuery({ maxWidth: 374 });

  const urlParams = new URLSearchParams(window.location.search);
  const [domain, setDomain] = useState<string>(urlParams.get('domain') || '');

  const { t } = useTranslation('components/modal');
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState<string>(i18n.language.split('-')[0]);
  const [recommendedSize, setRecommendedSize] = useState<
    | {
        labels: string[];
        availability: number;
        fit: number;
        random: number;
      }
    | undefined
  >();
  const urlParameters = new URLSearchParams(window.location.search);

  const MODAL_STEPS_TITLE = [
    t('steps.intro'),
    t('steps.gender'),
    t('steps.mobile_choice'),
    t('steps.qr_code'),
    t('steps.height'),
    t('steps.weight'),
    t('steps.age'),
    t('steps.belly'),
    t('steps.torso'),
    t('steps.hips'),
    t('steps.breasts'),
    t('steps.wait'),
    t('steps.loading'),
    t('steps.error'),
    recommendedSize
      ? recommendedSize.availability === 0
        ? t('steps.result.available')
        : recommendedSize.availability === 1
          ? t('steps.result.unavailable')
          : recommendedSize.availability === 2
            ? t('steps.result.fail')
            : t('steps.result.available')
      : t('steps.result.available')
  ];

  const [step, setStep] = useState(MODAL_STEPS.WAIT);
  const [showGoBack, setShowGoBack] = useState(true);
  const [showTitle, setShowTitle] = useState(true);
  const [showFooter, setShowFooter] = useState(true);

  useEffect(() => {
    setShowGoBack(
      step.number !== MODAL_STEPS.INTRO.number &&
        step.number !== MODAL_STEPS.LOADING.number &&
        step.number !== MODAL_STEPS.RESULT.number &&
        step.number !== MODAL_STEPS.ERROR.number
    );

    setShowTitle(
      (isMobile === true && step.number === MODAL_STEPS.RESULT.number) ||
        (isMobile === false &&
          step.number !== MODAL_STEPS.INTRO.number &&
          step.number !== MODAL_STEPS.QR_CODE.number &&
          step.number !== MODAL_STEPS.ERROR.number)
    );

    setShowFooter(
      (isMobile === false && step.number === MODAL_STEPS.QR_CODE.number) ||
        step.number === MODAL_STEPS.RESULT.number ||
        step.number === MODAL_STEPS.ERROR.number ||
        (isMobile === true && step.number === MODAL_STEPS.BREASTS.number)
    );

  }, [step]);

  useEffect(() => {
    const refererLang = appContext?.extractLangFromUrl();
    i18n.changeLanguage(refererLang ?? i18n.language.split('-')[0]);
  }, [appContext]);

  const [modalImage, setModalImage] = useState<string | null>(null);
  const [previousStep, setPreviousStep] = useState<{
    number: number;
    name: string;
  } | null>(null);

  const userContext = useUserContext() || undefined;
  const user = userContext?.user;

  const [uniqueGender, setUniqueGender] = useState<string | null>(null);

  const updateModalImage = (gender: string | null | undefined) => {
    if (!gender) setModalImage(null);
    if (appContext) {
      setModalImage(
        gender &&
          typeof gender === 'string' &&
          appContext.flow?.genders &&
          Object.keys(appContext.flow.genders).includes(gender)
          ? appContext.flow.genders[
              gender as keyof typeof appContext.flow.genders
            ]
          : appContext.flow?.genders &&
              Object.keys(appContext.flow.genders).includes('default')
            ? appContext.flow.genders['default']
            : null
      );
    }
  };

  useEffect(() => {
    setUniqueGender((prev) => appContext?.uniqueGender ?? prev);
    if (appContext) {
      updateModalImage(userStored?.gender);
      document.documentElement.style.setProperty(
        '--modal-font',
        appContext.style?.font ?? null
      );
      document.documentElement.style.setProperty(
        '--cta-color',
        appContext.style?.cta?.color ?? null
      );
      document.documentElement.style.setProperty(
        '--cta-border-radius',
        appContext.style?.cta?.borderRadius ?? null
      );
      document.documentElement.style.setProperty(
        '--intro-text-color',
        appContext.style?.intro ?? 'black'
      );
    }
  }, [appContext, userStored]);

  const gender = useRef('');
  const age = useRef(0);
  const height = useRef([0]);
  const weight = useRef(0);
  const units = useRef({ height: '', weight: '' });
  const bellyValue = useRef(0);
  const chestValue = useRef(0);
  const hipValue = useRef(0);
  const sizeValue = useRef('');
  const cupValue = useRef('');

  useEffect(() => {
    if (userContext) {
      if (
        !localStorage.getItem('uid') ||
        localStorage.getItem('domain') !== domain
      ) {
        const uid = userContext
          .newUser(domain)
          .then((v: any) => {
            userContext.newMeasure('scan', v || '');
          })
          .catch((e: any) => console.log(e));
      }

      if (!localStorage.getItem('mid')) {
        userContext.newMeasure('scan', '');
      }
    }
  }, [userContext]);

  useEffect(() => {
    if (recommendedSize) {
      setStep(MODAL_STEPS.RESULT);
    }
  }, [recommendedSize]);

  const sendQuestionAnswers = async () => {
    const INCHtoCM = (v: any) => v[0] * 30.48 + v[1] * 2.54;
    const LBStoKG = (v: number) => v * 0.453592;
    const data: {
      gender?: string;
      age?: number;
      height?: number;
      weight?: number;
      questions: {
        male_belly: string | null;
        male_hip: string | null;
        male_chest: string | null;
        female_belly: string | null;
        female_hip: string | null;
        female_bra_size?: string | null;
        female_bra_cup?: string | null;
      };
    } = {
      gender: gender.current,
      age: age.current,
      height:
        units.current.height === 'feet'
          ? INCHtoCM(height.current)
          : height.current[0],
      weight:
        units.current.weight === 'lbs'
          ? LBStoKG(weight.current)
          : weight.current,
      questions: {
        male_belly: bellyValue.current.toString(),
        male_hip: hipValue.current.toString(),
        male_chest: chestValue.current.toString(),
        female_belly: bellyValue.current.toString(),
        female_hip: hipValue.current.toString(),
        female_bra_size: sizeValue.current,
        female_bra_cup: cupValue.current === 'L' ? 'K' : cupValue.current
      }
    };
    if (gender.current == GENDERS.F) {
      data.questions.male_belly = '';
      data.questions.male_hip = '';
      data.questions.male_chest = '';

      if (sizeValue.current === '' && cupValue.current === '') {
        data.questions.female_bra_size = '90';
        data.questions.female_bra_cup = 'B';
      }
    } else {
      data.questions.female_belly = '';
      data.questions.female_hip = '';
      data.questions.female_bra_size = '';
      data.questions.female_bra_cup = '';

      if (hipValue.current === 0) {
        data.questions.male_hip = '2';
      }
    }

    const result = await questionPushAnswersAndTrigger(data);
    return result;
  };

  const [loadingContent, setLoadingContent] = useState({
    title: 'Veuillez patienter...',
    color: 'text-gray-700'
  });

  const [contentChange, setContentChange] = useState(false);

  const restart = () => {
    setLoading(false);

    setStep(MODAL_STEPS.INTRO);
    bellyValue.current = 0;
    chestValue.current = 0;
    hipValue.current = 0;
    sizeValue.current = '';
    cupValue.current = '';
    gender.current = '';
    removeLocalStore('mid');
    removeLocalStore('user');
    removeLocalStore('productSize');

    const urlParams = new URLSearchParams(window.location.search);
    removeLocalStore(`${urlParams.get('pid')}`);

    if (localStorage.getItem('user')) {
      setLocalStore('user', JSON.stringify(user));
    }
  };

  const handleGoBack = () => {
    if (step.number === MODAL_STEPS.HEIGHT.number) {
      setStep(MODAL_STEPS.INTRO);
    } else if (step.number === MODAL_STEPS.WEIGHT.number) {
      setStep(MODAL_STEPS.HEIGHT);
    } else if (step.number === MODAL_STEPS.AGE.number) {
      setStep(MODAL_STEPS.WEIGHT);
    } else if (step.number === MODAL_STEPS.BELLY.number) {
      setStep(MODAL_STEPS.AGE);
    } else if (step.number === MODAL_STEPS.HIPS.number) {
      setStep(MODAL_STEPS.BELLY);
    } else if (step.number === MODAL_STEPS.TORSO.number) {
      setStep(MODAL_STEPS.BELLY);
    } else if (step.number === MODAL_STEPS.BREASTS.number) {
      setStep(MODAL_STEPS.HIPS);
    } else if (step.number === MODAL_STEPS.QR_CODE.number) {
      if (previousStep !== null) {
        setStep(previousStep);
        setPreviousStep(null);
      } else {
        setStep(MODAL_STEPS.AGE);
      }
    } else if (step.number === MODAL_STEPS.RESULT.number) {
      restart();
    } else if (step.number === MODAL_STEPS.PRODUCTS.number) {
      setStep(MODAL_STEPS.RESULT);
    }
  };

  useEffect(() => {
    console.log('step', step);
    if (step.number !== 0) {
      updateModalImage(null);
    } else if (step.number === MODAL_STEPS.INTRO.number) {
      if (gender.current) {
        updateModalImage(gender?.current);
      } else {
        updateModalImage('default');
      }
    }
  }, [step, gender]);

  useEffect(() => {
    if (
      userContext &&
      (step.number === MODAL_STEPS.INTRO.number ||
        step.number === MODAL_STEPS.QR_CODE.number)
    )
      userContext.newMeasure('scan', '');
  }, [step, userContext]);

  return (
    <Dialog
      aria-labelledby="klipfit-modal"
      onClose={() => handleModal(!isModalOpen, false)}
      open={isModalOpen}
      PaperProps={{
        style: {
          position: isMobile ? 'fixed' : undefined,
          margin: isMobile ? '0' : undefined,
          bottom: isMobile ? '0%' : undefined,
          minWidth: isMobile ? '100%' : '950px',
          borderRadius: isMobile ? '16px 16px 0 0' : '10px',
          maxWidth: isMobile ? '100%' : '950px',
          maxHeight: '100%',
          overflow: 'hidden',
          padding: 0
        }
      }}
    >
      <Suspense>
        <DialogContent
          sx={{
            backgroundImage:
              step.number === MODAL_STEPS.INTRO.number
                ? `url(${modalImage})`
                : undefined,
            backgroundSize: 'cover',
            backgroundPosition: isMobile ? (domain.includes('rondorff') ? '-400px' : '-100px') : undefined,
            height: isSmallMobile
              ? step.number !== MODAL_STEPS.RESULT.number
                ? '520px'
                : '560px'
              : isMobile
                ? step.number !== MODAL_STEPS.RESULT.number
                  ? '570px'
                  : '700px'
                : step.number !== MODAL_STEPS.RESULT.number
                  ? '550px'
                  : '650px', // Adjust the height as needed,
            minWidth: '100%',
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'start',
            color:
              isMobile && step.number === MODAL_STEPS.INTRO.number
                ? 'white'
                : null,
            flexDirection:
              step.number === MODAL_STEPS.INTRO.number ||
              step.number === MODAL_STEPS.RESULT.number
                ? 'row'
                : 'column',
            padding: '0px',
            paddingBottom: isMobile ? '70px' : '0',
            transition: 'background-image 0.3s ease-in'
          }}
        >
          {isMobile && step.number === MODAL_STEPS.INTRO.number && (
            <Filter type={'black'} />
          )}
          {isMobile !== true && step.number === MODAL_STEPS.RESULT.number && (
            <div
              style={{
                height: '100%',
                minWidth: '400px',
                pointerEvents: 'none',
                backgroundImage: `url(${
                  pid && Object.keys(lacosteProducts).includes(pid)
                    ? lacosteProducts[pid].image
                    : modalImage
                })`,
                backgroundSize: 'cover',
                backgroundPosition: '20%'
              }}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%'
            }}
          >
            <Header
              showGoBack={showGoBack}
              handleGoBack={handleGoBack}
              showTitle={showTitle}
              title={MODAL_STEPS_TITLE[step.number]}
              language={language}
              setLanguage={setLanguage}
              handleModal={handleModal}
            />
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                overflow: 'hidden',
                cursor: 'default'
              }}
            >
              {step.number === MODAL_STEPS.RESULT.number &&
                loading &&
                !getLocalAndParse('productSize') && (
                  <div
                    className={`flex flex-col items-center justify-center text-center font-unbounded p-[80px] ${
                      loadingContent.title == 'Loading'
                        ? 'sm:px-[200px] sm:py-[100px]'
                        : null
                    }`}
                    role="status"
                  >
                    {!contentChange && (
                      <>
                        <Lottie
                          animationData={require('../../assets/animations/loading_black.json')}
                          style={{
                            width: '200px',
                            height: 'auto'
                          }}
                        />
                        <span className="sr-only">{loadingContent.title}</span>
                      </>
                    )}
                    <h3
                      className={`mt-2  text-lg font-semibold ${loadingContent.color}`}
                    >
                      {loadingContent.title}
                    </h3>
                  </div>
                )}
              {step.number === MODAL_STEPS.WAIT.number && (
                <Wait
                  domain={urlParameters.get('domain')}
                  setConfig={(data) =>
                    /*setModalConfig(data)*/ console.log(data)
                  }
                  intro={() => setStep(MODAL_STEPS.INTRO)}
                  result={(r: any) => setRecommendedSize(r)}
                />
              )}
              {isMobile && step.number === MODAL_STEPS.INTRO.number ? (
                <StepIntroMobile
                  nextStep={() => setStep(MODAL_STEPS.HEIGHT)}
                  setLocalGender={(value: string) => {
                    updateModalImage(value);
                    gender.current = value;
                  }}
                  uniqueGender={uniqueGender}
                />
              ) : (
                step.number === MODAL_STEPS.INTRO.number && (
                  <StepIntroDesktop
                    nextStep={() => setStep(MODAL_STEPS.HEIGHT)}
                    setLocalGender={(value: string) => {
                      updateModalImage(value);
                      gender.current = value;
                    }}
                    uniqueGender={uniqueGender}
                  />
                )
              )}
              {step.number === MODAL_STEPS.HEIGHT.number && (
                <StepHeight
                  nextStep={() => setStep(MODAL_STEPS.WEIGHT)}
                  units={units}
                  height={height}
                />
              )}
              {step.number === MODAL_STEPS.WEIGHT.number && (
                <StepWeight
                  nextStep={() => setStep(MODAL_STEPS.AGE)}
                  units={units}
                  weight={weight}
                />
              )}
              {step.number === MODAL_STEPS.AGE.number && (
                <StepAge
                  nextStep={() => setStep(MODAL_STEPS.BELLY)}
                  age={age}
                />
              )}
              {step.number === MODAL_STEPS.BELLY.number && (
                <StepBelly
                  gender={gender.current || ''}
                  nextStep={() => {
                    if (gender.current === 'male') {
                      setStep(MODAL_STEPS.TORSO);
                    } else {
                      setStep(MODAL_STEPS.HIPS);
                    }
                  }}
                  skip={() => {
                    setPreviousStep(MODAL_STEPS.BELLY);
                    setStep(MODAL_STEPS.QR_CODE);
                  }}
                  value={bellyValue}
                />
              )}
              {step.number === MODAL_STEPS.HIPS.number && (
                <StepHips
                  gender={gender.current || ''}
                  nextStep={() => {
                    if (gender.current === 'male') {
                      setStep(MODAL_STEPS.TORSO);
                    } else {
                      setStep(MODAL_STEPS.BREASTS);
                    }
                  }}
                  skip={() => {
                    setPreviousStep(MODAL_STEPS.HIPS);
                    setStep(MODAL_STEPS.QR_CODE);
                  }}
                  value={hipValue}
                />
              )}
              {step.number === MODAL_STEPS.TORSO.number && (
                <StepTorso
                  nextStep={async () => {
                    await newMeasure('question');
                    setStep(MODAL_STEPS.LOADING);
                  }}
                  skip={() => {
                    setPreviousStep(MODAL_STEPS.TORSO);
                    setStep(MODAL_STEPS.QR_CODE);
                  }}
                  value={chestValue}
                />
              )}
              {step.number === MODAL_STEPS.BREASTS.number && (
                <StepBreasts
                  value_1={sizeValue}
                  value_2={cupValue}
                  nextStep={async () => {
                    await newMeasure('question');

                    setStep(MODAL_STEPS.LOADING);
                  }}
                  skipStep={async () => {
                    await newMeasure('question');

                    setStep(MODAL_STEPS.LOADING);
                  }}
                />
              )}
              {step.number === MODAL_STEPS.QR_CODE.number && (
                <StepQRCode
                  showSkip={previousStep === null}
                  pid={pid}
                  skipScan={() => setStep(MODAL_STEPS.BELLY)}
                  skipToResult={() => setStep(MODAL_STEPS.RESULT)}
                  setRecommendedSize={setRecommendedSize}
                />
              )}
              {step.number === MODAL_STEPS.LOADING.number && (
                <ResultLoading
                  nextStep={(value: boolean) => {
                    const nextStep = value
                      ? MODAL_STEPS.RESULT
                      : MODAL_STEPS.ERROR;
                    setStep(nextStep);
                  }}
                  sendAnswers={sendQuestionAnswers}
                  setRecommendedSize={setRecommendedSize}
                />
              )}
              {step.number === MODAL_STEPS.ERROR.number && (
                <ResultError
                  refresh={() => setStep(MODAL_STEPS.LOADING)}
                  quit={() => handleModal(false, false)}
                />
              )}
              {step.number === MODAL_STEPS.RESULT.number && (
                <Result
                  gender={gender.current}
                  restart={restart}
                  recommendedSize={recommendedSize}
                />
              )}
            </Box>
            <Footer
              showFooter={showFooter}
              isIntro={step.number === MODAL_STEPS.INTRO.number}
            />
          </Box>
          <div id="preload" style={{ display: 'none' }}>
            <img src={modalImage ?? ''} width="1" height="1" />
          </div>
        </DialogContent>
      </Suspense>
    </Dialog>
  );
};

export default KleepModal;
