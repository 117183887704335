import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { getLocalAndParse, getLocalSessionAndParse } from './localStoreUtils';

import { newUser, newMeasure } from '../api/endpoints';

interface IPropsUserContext {
  user: {
    age?: number;
    height?: number;
    heightUnit?: string;
    weight?: number;
    weightUnit?: string;
    gender?: string;
  };
  contentResults: any;
  uid: any;
  currentSize: any;
  selectedSize: any;
  setUser: (content: any) => void;
  addResultContent: (content: any) => void;
  updateCurrentSize: (content: any) => void;
  updateSelectedSize: (content: any) => void;
  newMeasure: (content: string, user_id: string) => Promise<string>;
  newUser: (content: any) => any;
}

const UserContext = React.createContext<IPropsUserContext | null>(null);

export const UserContextProvider = (props: any) => {
  const [user, setUser] = useState({
    age: undefined,
    height: undefined,
    weight: undefined,
    gender: undefined
  });
  const [uid, setUid] = useState(null);

  const [contentResults, setContentResults] = useState(null);
  const [currentSize, setCurrentSize] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);

  useEffect(() => {
    const storedUser = getLocalSessionAndParse('user');
    const storedUid = getLocalSessionAndParse('uid');

    if (storedUser) {
      setUser(storedUser);
    }
    if (storedUid) {
      setUid(storedUid);
    }

    setContentResults(null);
  }, [user, setUid]);

  useEffect(() => {
    if (uid) {
      localStorage.setItem('uid', uid);
    }

    const isUserDefined = Object.values(user).some(
      (value) => value !== undefined
    );
    if (isUserDefined) {
      const oldUser = getLocalAndParse('user');
      const newUser = { ...oldUser, ...user };

      localStorage.setItem('user', JSON.stringify(newUser));
    }
  }, [uid, user]);

  const addResultContent = (content: any) => {
    setContentResults(content);
  };

  const updateSelectedSize = (content: any) => {
    setSelectedSize(content);
  };

  const updateCurrentSize = (content: any) => {
    setCurrentSize(content);
  };

  return (
    <UserContext.Provider
      value={{
        uid,
        user,
        contentResults,
        currentSize,
        selectedSize,
        setUser,
        addResultContent,
        updateCurrentSize,
        updateSelectedSize,
        newUser,
        newMeasure
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useUserContext = () => {
  return useContext(UserContext);
};
