import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import './desktop.scss';
import Ruler from '../../../molecules/Ruler/Ruler';

interface IPropsMeasuresDesktop {
  units: { height: string; weight: string };
  setUnits: (value: { height: string; weight: string }) => void;
  height: number[] | undefined;
  setHeight: (value: (number | undefined)[] | undefined) => void;
  error: {
    error: boolean;
    message: string;
  };
}

const HeightDesktop: FC<IPropsMeasuresDesktop> = ({
  units,
  setUnits,
  height,
  setHeight,
  error
}) => {
  const { t } = useTranslation('components/measures');

  return (
    <div className="height_desktop">
      <p className="height_desktop__description">{t('height.description')}</p>
      <Ruler
        value={height || [0]}
        onValueChange={(value) => {
          if (units.height === 'cm') {
            setHeight([value]);
          } else {
            setHeight([
              4 + Math.floor(value / 12.0),
              value - 12 * Math.floor(value / 12.0)
            ]);
          }
        }}
        units={['cm', 'feet']}
        unit={units.height}
        onUnitChange={(unit) => setUnits({ ...units, height: unit })}
        error={error}
      />
    </div>
  );
};

export default HeightDesktop;
