export const lacosteProducts: {
  [key: string]: { image: string; sizeGuide: number };
} = {
  '8956154872090': {
    image:
      'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw1c64887c/WH2528_031_20.jpg?imwidth=915&impolicy=product',
    sizeGuide: 0
  },
  '8956157296922': {
    image:
      'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw046f4961/HH2704_ML4_20.jpg?imwidth=915&impolicy=product',
    sizeGuide: 1
  },
  '8956157985050': {
    image:
      'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw48496706/TH6712_031_20.jpg?imwidth=915&impolicy=product',
    sizeGuide: 0
  },
  '8956158574874': {
    image:
      'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dwf0a21413/TF9246_031_20.jpg?imwidth=915&impolicy=product',
    sizeGuide: 2
  },
  '8956159623450': {
    image:
      'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw680fa4e2/BH0538_166_20.jpg?imwidth=915&impolicy=product',
    sizeGuide: 0
  },
  '8956160442650': {
    image:
      'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw5247b96e/EF1031_PIM_20.jpg?imwidth=915&impolicy=product',
    sizeGuide: 2
  },
  '8956161229082': {
    image:
      'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw7ba46e7a/L1264_SXL_20.jpg?imwidth=915&impolicy=product',
    sizeGuide: 0
  },
  '8956161786138': {
    image:
      'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw97c10fde/PF0504_001_20.jpg?imwidth=915&impolicy=product',
    sizeGuide: 0
  },
  '8956162769178': {
    image:
      'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw7e27779f/CH8528_001_20.jpg?imwidth=915&impolicy=product',
    sizeGuide: 0
  },
  '8956163293466': {
    image:
      'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw893c9b3e/OF1561_031_20.jpg?imwidth=915&impolicy=product',
    sizeGuide: 0
  },
  '8956164112666': {
    image:
      'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dwdf0730fa/IF8167_001_20.jpg?imwidth=915&impolicy=product',
    sizeGuide: 0
  },
  '8956164833562': {
    image:
      'https://image1.lacoste.com/dw/image/v2/AAQM_PRD/on/demandware.static/Sites-FR-Site/Sites-master/fr/dw3846f7b2/7H3394_8X0_20.jpg?imwidth=915&impolicy=product',
    sizeGuide: 0
  }
};
