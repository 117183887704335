import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Ruler from '../../../molecules/Ruler/Ruler';
import { useAppContext } from '../../../../../context/AppContext';

import './mobile.scss';

interface IPropsAgeMobile {
  units: { height: string; weight: string };
  setUnits: (value: { height: string; weight: string }) => void;
  age: number[];
  setAge: (value: number | undefined) => void;
  error: {
    error: boolean;
    message: string;
  };
}

const AgeMobile: FC<IPropsAgeMobile> = ({
  units,
  setUnits,
  age,
  setAge,
  error
}) => {
  const appContext = useAppContext() || undefined;
  const { t } = useTranslation('components/measures');

  return (
    <div className="age_mobile">
      <div className="age_mobile__text">
        <p
          className="age_mobile__text__title"
          style={{
            textTransform:
              appContext?.style.caps === true ? 'uppercase' : undefined
          }}
        >
          {t('age.title')}
        </p>
        <p className="age_mobile__text__description">{t('age.description')}</p>
      </div>
      <Ruler
        value={age || [0]}
        onValueChange={(value) => {
          setAge(value);
        }}
        units={['years']}
        unit={'years'}
        onUnitChange={(unit) => setUnits({ ...units, height: unit })}
        error={error}
      />
    </div>
  );
};

export default AgeMobile;
