import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ArrowBack } from '@mui/icons-material';

export const ArrowLeft = () => {
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const isSmallMobile = useMediaQuery({ maxWidth: 374 });

  return (
    <ArrowBack
      style={{
        color: 'black',
        fontSize: isSmallMobile ? '20px' : isMobile ? '24px' : '28px'
      }}
    />
  );
};
