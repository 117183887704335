import React, { FC, useState } from 'react';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import Lottie from 'lottie-react';
import { useMediaQuery } from 'react-responsive';
import { Trans, useTranslation } from 'react-i18next';
import EastRoundedIcon from '@mui/icons-material/EastRounded';

import { useAppContext } from '../../../context/AppContext';

import Stepper from '../molecules/Stepper/Stepper';

import chest_1_male from '../../../assets/questionnaire/chest/chest-1-male.svg';
import chest_2_male from '../../../assets/questionnaire/chest/chest-2-male.svg';
import chest_3_male from '../../../assets/questionnaire/chest/chest-3-male.svg';

import './stepTorso.scss';

const getTextTransform = (
  caseValue?: string
): 'none' | 'capitalize' | 'uppercase' | 'lowercase' => {
  return ['none', 'capitalize', 'uppercase', 'lowercase'].includes(
    caseValue || ''
  )
    ? (caseValue as 'none' | 'capitalize' | 'uppercase' | 'lowercase')
    : 'none';
};

interface IPropsTorsoChoice {
  image: string;
  onClick: () => void;
  text: string;
  isSelected?: boolean;
}

const TorsoChoice: FC<IPropsTorsoChoice> = ({
  image,
  onClick,
  text,
  isSelected
}) => {
  const [loading, setLoading] = useState(true);
  const appContext = useAppContext() || undefined;

  return (
    <button
      className={`torso_choice ${
        isSelected ? 'torso_choice--selected' : undefined
      }`}
      onClick={onClick}
      style={{
        textTransform: getTextTransform(appContext?.style?.cta?.case)
      }}
    >
      {loading === true ? (
        <Lottie
          className="torso_choice__loading"
          animationData={require('../../../assets/animations/loading_image.json')}
        />
      ) : null}
      <img
        className={`torso_choice__image ${
          loading ? 'torso_choice__image--loading' : undefined
        }`}
        src={image}
        onLoad={() => setLoading(false)}
      />
      {text}
    </button>
  );
};

interface IPropsStepTorso {
  nextStep: () => void;
  skip: () => void;
  value: {
    current: number;
  };
}

const StepTorso: FC<IPropsStepTorso> = ({ nextStep, skip, value }) => {
  const appContext = useAppContext() || undefined;
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const { t } = useTranslation('components/torso');
  const [selected, setSelected] = useState<number | undefined>(value.current);

  return (
    <div className="torso">
      <div className="torso__text">
        {isMobile ? (
          <p
            className="torso__text__title"
            style={{
              textTransform: getTextTransform(appContext?.style?.cta?.case)
            }}
          >
            <Trans
              ns="components/torso"
              i18nKey="title"
              values={{ separator: '\n' }}
            />
          </p>
        ) : null}
        <p className="torso__text__description">{t('description')}</p>
      </div>
      <div className="torso__body">
        <TorsoChoice
          image={chest_1_male}
          onClick={() => {
            value.current = 1;
            setSelected(1);
          }}
          text={t('size.one')}
          isSelected={selected === 1}
        />
        <TorsoChoice
          image={chest_2_male}
          onClick={() => {
            value.current = 2;
            setSelected(2);
          }}
          text={t('size.two')}
          isSelected={selected === 2}
        />
        <TorsoChoice
          image={chest_3_male}
          onClick={() => {
            value.current = 3;
            setSelected(3);
          }}
          text={t('size.three')}
          isSelected={selected === 3}
        />
      </div>
      <Stepper stepsNum={2} step={2} key={1} className="torso__stepper" />
      <div className="torso__buttons">
        <button
          disabled={selected === undefined || selected === 0}
          className="torso__buttons__continue"
          style={{
            textTransform: getTextTransform(appContext?.style?.cta?.case)
          }}
          onClick={nextStep}
        >
          {appContext?.style?.caps === true
            ? t('buttons.continue').toUpperCase()
            : t('buttons.continue')}
          <EastRoundedIcon className="torso__buttons__continue__icon" />
        </button>
        <button
          className="torso__buttons__scan"
          onClick={skip}
          style={{
            textTransform:
              getTextTransform(appContext?.style?.cta?.case) === 'lowercase'
                ? 'lowercase'
                : 'none'
          }}
        >
          <CameraAltOutlinedIcon className="torso__buttons__scan__icon" />
          {t('buttons.scan')}
        </button>
      </div>
    </div>
  );
};

export default StepTorso;
