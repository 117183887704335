import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import './mobile.scss';
import Ruler from '../../../molecules/Ruler/Ruler';

import { useAppContext } from '../../../../../context/AppContext';

interface IPropsMeasuresMobile {
  units: { height: string; weight: string };
  setUnits: (value: { height: string; weight: string }) => void;
  height: number[] | undefined;
  setHeight: (value: (number | undefined)[] | undefined) => void;
  error: {
    error: boolean;
    message: string;
  };
}

const HeightMobile: FC<IPropsMeasuresMobile> = ({
  units,
  setUnits,
  height,
  setHeight,
  error
}) => {
  const appContext = useAppContext() || undefined;
  const { t } = useTranslation('components/measures');

  return (
    <div className="height_mobile">
      <div className="height_mobile__text">
        <p
          className="height_mobile__text__title"
          style={{
            textTransform:
              appContext?.style.caps === true ? 'uppercase' : undefined
          }}
        >
          {t('height.title')}
        </p>
        <p className="height_mobile__text__description">
          {t('height.description')}
        </p>
      </div>
      <Ruler
        value={height || [0]}
        onValueChange={(value) => {
          if (units.height === 'cm') {
            setHeight([value]);
          } else {
            setHeight([
              4 + Math.floor(value / 12.0),
              value - 12 * Math.floor(value / 12.0)
            ]);
          }
        }}
        units={['cm', 'feet']}
        unit={units.height}
        onUnitChange={(unit) => setUnits({ ...units, height: unit })}
        error={error}
      />
    </div>
  );
};

export default HeightMobile;
